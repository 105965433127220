























import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchComplianceTotal } from '@/api/drilldown';
import { View, FetchedData } from '@/interfaces';

@Component({
  components: {
    TableComponent,
  },
})
export default class ComplianceTotal extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: [
      'Substance',
      'CAS_Number',
      'Mass',
    ],
    default: false,
    selected: true,
  };

  public notSortable: string[] = [];

  async mounted(): Promise<void> {
    this.getURLParams();
    await this.fetchData();
    this.isLoadingNewRecords = false;
    this.isExportDisabled = false;
    this.emitListener();
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    const declarationData = await fetchComplianceTotal({
      id: this.idDeclaration,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.data = {
      itemsLength: declarationData.itemsLength,
      items: declarationData.items,
      views: declarationData.views,
    };
    this.isExportDisabled = false;
  }

  get processedData(): FetchedData {
    const lengthRoot = this.data.items.length;
    return {
      itemsLength: lengthRoot,
      items: this.data.items,
      views: this.data.views,
    };
  }
}
