










































































































































































import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import SearchTool from '@/components/Navigators/SearchTool.vue';
import ProductDetailNavigator from '@/components/Navigators/ProductDetailNavigator.vue';
import User from '@/models/User';
import SimpleSnackbar from '@/components/Snackbar/SimpleSnackbar.vue';
import MultifactorAuth from '@/components/Dialog/MultifactorAuth.vue';
import PasswordChange from '@/components/Dialog/PasswordChange.vue';
import UserMenu from '@/components/Navigators/UserMenu.vue';
import eventBus from '@/eventBus';
import UIPermissions from '@/models/UIPermissions';
import historyStore from '@/stores/history';
import { MultiFactorAuthForm, DownloadFile, Route, BrowsingHistory } from '@/interfaces';
import fetchFile from '@/api/file';

@Component({
  components: {
    LocaleSwitcher,
    SearchTool,
    MultifactorAuth,
    PasswordChange,
    SimpleSnackbar,
    UserMenu,
    ProductDetailNavigator,
  },
})
export default class Navigator extends Vue {
  @Prop() id!: string;

  @Prop() idDeclaration!: string;

  @Inject('user') user!: User;

  public partNumber = '';

  public items: DownloadFile[] = [
    {
      fileName: 'Padstack',
      endpoint: 'padstack',
    },
    {
      fileName: 'Shape',
      endpoint: 'shape',
    },
  ];

  public store = historyStore();

  public routesCharts: Route[] = [
    {
      to: 'statistics.vendor',
      label: this.$t('navigator.charts.vendor'),
      name: [UIPermissions.VENDOR_PART_STATUS],
    },
    {
      to: 'statistics.endOfLife',
      label: this.$t('navigator.charts.endOfLife'),
      name: [UIPermissions.ESTIMATED_END_OF_LIFE],
    },
    {
      to: 'statistics.customerProject',
      label: this.$t('navigator.charts.customerProject'),
      name: [UIPermissions.CUSTOMER_PROJECT],
    },
  ];

  public routesDeclaration: Route[] = [
    {
      to: 'declaration.general',
      label: this.$t('navigator.declarations.general'),
      name: [
        UIPermissions.CAS_NUMBER_TAB,
        UIPermissions.SUBSTANCE_NAME_TAB,
        UIPermissions.PPM_RESULT_TAB,
      ],
    },
    {
      to: 'declaration.reports',
      label: this.$t('navigator.declarations.reports'),
      name: [
        UIPermissions.CAS_NUMBER_TAB,
        UIPermissions.SUBSTANCE_NAME_TAB,
        UIPermissions.PPM_RESULT_TAB,
      ],
    },
  ];

  public routesComponent: Route[] = [
    {
      to: 'item.general',
      label: this.$t('navigator.general'),
      name: [UIPermissions.GENERAL_INFORMATION],
    },
    {
      to: 'item.customer',
      label: this.$t('navigator.customer'),
      name: [UIPermissions.ALLOWED],
    },
    {
      to: 'item.attrributes',
      label: this.$t('navigator.attrributes'),
      name: [
        UIPermissions.MANUFACTERING_PROCESS_DATA,
        UIPermissions.SUPPLIER_ANALYSIS,
        UIPermissions.ENVIOMENTAL_INFORMATION,
        UIPermissions.EDA_AND_CAD_INFORMATION,
        UIPermissions.TECHNICAL_INFORMATION,
      ],
    },
    {
      to: 'item.vendor',
      label: this.$t('navigator.vendor'),
      name: [UIPermissions.VENDOR_TAB],
    },
    {
      to: 'item.compliance',
      label: this.$t('navigator.compliance.name'),
      name: [UIPermissions.COMPLIANCE_TAB],
      subRoute: [
        {
          to: 'item.compliance.main',
          label: this.$t('navigator.compliance.main'),
          name: [UIPermissions.COMPLIANCE],
        },
        {
          to: 'item.compilance.documents',
          label: this.$t('navigator.compliance.documents'),
          name: [UIPermissions.DECLARATION_DOCUMETNS],
        },
      ],
    },
    // @TODO implement in the future
    // {
    //   to: 'item.supplierData',
    //   label: this.$t('navigator.supplierData'),
    //   name: [UIPermissions.SUPPLIER_DATA],
    // },
    {
      to: 'item.documents',
      label: this.$t('navigator.documents'),
      name: [UIPermissions.ALLOWED],
    },
    {
      to: 'item.statistics',
      label: this.$t('navigator.statistics.statistics'),
      name: [UIPermissions.BOM_STATISTIC],
      subRoute: [
        {
          to: 'item.statistics.obsolescence',
          label: this.$t('navigator.statistics.obsolescence'),
          name: [UIPermissions.BOM_STATISTIC_OBSOLESCENCE],
        },
        {
          to: 'item.statistics.customerPartObsolescence',
          label: this.$t('navigator.statistics.customerPartObsolescence'),
          name: [UIPermissions.BOM_STATISTIC_CUSTOMER_RISK_STATUS],
        },
        {
          to: 'item.statistics.rohs',
          label: this.$t('navigator.statistics.rohs'),
          name: [UIPermissions.BOM_STATISTIC_ROHS],
        },
        {
          to: 'item.statistics.chartsVendor',
          label: this.$t('navigator.statistics.chartsVendor'),
          name: [UIPermissions.BOM_ALL_COMPONENTS],
        },
        {
          to: 'item.statistics.chartsRisk',
          label: this.$t('navigator.statistics.chartsRisk'),
          name: [UIPermissions.BOM_RISK_ANALYSIS],
        },
      ],
    },
    {
      to: 'item.partList',
      label: this.$t('navigator.partList'),
      name: [UIPermissions.PART_LIST],
    },
    {
      to: 'item.whereUsed',
      label: this.$t('navigator.whereUsed'),
      name: [UIPermissions.WHERE_USED],
    },
    {
      to: 'item.spiceModel',
      label: this.$t('navigator.spiceModel'),
      name: [UIPermissions.PSPICE_MODEL],
    },
    {
      to: 'item.schematicPart',
      label: this.$t('navigator.schematicPart'),
      name: [UIPermissions.SCHEMATIC_PART],
    },
    {
      to: 'item.altiumSchematicPart',
      label: this.$t('navigator.altiumSchematicPart'),
      name: [UIPermissions.ALTIUM_SCHEMATIC_PART],
    },
    {
      to: 'item.pcbFootprint',
      label: this.$t('navigator.pcbFootprint'),
      name: [UIPermissions.PCB_FOOTPRINT],
    },
    {
      to: 'item.altiumPcbFootprint',
      label: this.$t('navigator.altiumPcbFootprint'),
      name: [UIPermissions.ALTIUM_PCB_FOOTPRINT],
    },
    {
      to: 'item.threeDModel',
      label: this.$t('navigator.threeDModel'),
      name: [UIPermissions.THREE_D_MODEL],
    },
    {
      to: 'item.history',
      label: this.$t('navigator.history'),
      name: [UIPermissions.HISTORY],
    },
  ];

  public routesCompliance: Route[] = [
    {
      to: 'item.compliance.main.overallCompliance.declaration',
      label: this.$t('navigator.overallCompliance.declaration'),
      name: [UIPermissions.DECLARATION],
    },
    {
      to: 'item.compliance.main.overallCompliance.specification',
      label: this.$t('navigator.overallCompliance.specification'),
      name: [UIPermissions.SPECIFICATION],
    },
    {
      to: 'item.compliance.main.overallCompliance.total',
      label: this.$t('navigator.overallCompliance.total'),
      name: [UIPermissions.COMPLIANCE_TOTAL],
    },
  ];

  get routesComplianceBasedOnComponentType(): Route[] {
    if (!this.partNumber.startsWith('5')) {
      return this.routesCompliance.filter((e) => !e.name.includes(UIPermissions.COMPLIANCE_TOTAL));
    }
    return this.routesCompliance;
  }

  public dialog = false;

  public passwordDialog = false;

  public showSnackbar = false;

  public successMesage: TranslateResult = '';

  public menu = false;

  get isMain(): boolean {
    return this.$route.name === 'main';
  }

  get themeDark(): boolean {
    return this.$vuetify.theme.dark;
  }

  set themeDark(value: boolean) {
    this.$vuetify.theme.dark = value;
  }

  get itemRoute(): boolean {
    return this.$route.name?.includes('item.') || false;
  }

  get browsingItems(): BrowsingHistory[] | null {
    return this.store.values;
  }

  get disableDownload(): boolean {
    return true;
    // TODO Change that
    // return (
    //   this.user.downloadPermissions.includes('PCB Shape') &&
    //   !this.user.downloadPermissions.includes('PCB Padstack')
    // );
  }

  get enableComplianceNavigation(): boolean {
    return !!this.$route.name?.includes('overallCompliance');
  }

  get enableComponentNavigation(): boolean {
    return !!this.$route.name?.includes('item.');
  }

  get enableDeclarationNavigation(): boolean {
    return !!this.$route.name?.includes('declaration.');
  }

  get enableChartNavigation(): boolean {
    return !!(
      this.$route.name === 'statistics.vendor' ||
      this.$route.name === 'statistics.endOfLife' ||
      this.$route.name === 'statistics.customerProject'
    );
  }

  public changeTheme(): void {
    this.themeDark = !this.themeDark;
    if (this.themeDark) {
      window.localStorage.setItem('theme', 'dark');
    } else {
      window.localStorage.setItem('theme', 'light');
    }
  }

  async beforeCreate(): Promise<void> {
    eventBus.$on('partNumber', (partNumber: string) => {
      if (!partNumber) {
        this.$router.push('/main');
      }
      this.partNumber = partNumber;
    });
  }

  public goToOldRequestUrl(): void {
    window.open('https://old.dynamic-precision.com/Client-login2', '_blank');
  }

  public closeForm(value: MultiFactorAuthForm): void {
    this.dialog = false;
    if (value.success) {
      this.successMesage = value.exists
        ? this.$t('multiFactorAuth.successDisable')
        : this.$t('multiFactorAuth.success');
      this.showSnackbar = !this.showSnackbar;
    }
  }

  public closePasswordForm(): void {
    this.passwordDialog = false;
    this.successMesage = this.$t('login.newPassDone.title');
    this.showSnackbar = !this.showSnackbar;
  }

  public openDialog(): void {
    this.dialog = true;
  }

  public openPasswordDialog(): void {
    this.passwordDialog = true;
  }

  public closeHistory(): void {
    this.menu = false;
  }

  public isDisabled(file: string | undefined): boolean {
    return this.user.downloadPermissions.includes(`PCB ${file}`);
  }

  public async downloadFiles(item: DownloadFile): Promise<void> {
    const response = await fetchFile(item);
    if (!response) {
      eventBus.$emit('generalError', {
        type: 'error',
        message: this.$t('error.file').toString(),
      });
    }
  }

  public navigatToMain(): void {
    if (this.$route.name === 'main') {
      eventBus.$emit('resetForm');
    } else {
      this.$router.push('/main');
    }
  }

  public navigatToStatistics(): void {
    if (
      this.$route.name !== 'statistics.vendor' &&
      this.$route.name !== 'statistics.endOfLife' &&
      this.$route.name !== 'statistics.customerProject'
    ) {
      this.$router.push('/statistics');
    }
  }

  public navigateToDeclaration(): void {
    if (this.$route.name !== 'declaration.general' && this.$route.name !== 'declaration.reports') {
      this.$router.push('/declaration');
    }
  }

  public restAttributes(): void {
    eventBus.$emit('resetForm');
  }
}
