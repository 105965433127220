



























import { Component, Mixins } from 'vue-property-decorator';
import { StatisticDataBOM, StatisticParameter, View, IExport, FetchedItem, FetchedData } from '@/interfaces';
import StatisticBOMContainer from '@/components/Statistics/Containers/StatisticBOMContainer.vue';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import Filters from '@/models/Filters';
import { fetchStatisticsCustomerChart, fetchBOMCustomerPartObsolescence } from '@/api/drilldown';
import exportData from '@/helpers/exportData';

@Component({
  components: {
    TableComponent,
    StatisticBOMContainer,
  },
})
export default class CustomerPartObsolescence extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: ['Customer_Part_Number', 'Customer', 'Customer_PN_Part_Risk_Status_Current_Year'],
    default: false,
    selected: true,
  };

  public chartData: StatisticDataBOM = {
    message: '',
    status: [],
  };

  public notSortable: string[] = [Filters.CUSTOMER];

  public isLoadingChartData = true;

  public status: string | null = null;

  public year: number | null = null;

  get exportTitle(): string {
    if (!this.status && !this.year) {
      return 'cpo';
    }
    if (!this.status) {
      return `cpo_${this.year}`;
    }
    return `cpo_${this.year}_${this.status}`;
  }

  get field(): string {
    if (!this.year) {
      return '';
    }
    const currentYear = new Date().getFullYear();
    const diff = this.year - currentYear;
    if (diff === 0) {
      return 'Customer_PN_Part_Risk_Status_Current_Year';
    }
    return `Customer_PN_Part_Risk_Status_+${diff}_year`;
  }

  get title(): string {
    if (!this.status && !this.year) {
      return 'BOM Customer Parts ALL';
    }
    if (!this.status) {
      return `BOM Customer Parts from year ${this.year}`;
    }
    return `BOM Customer Parts with Risk Status ${this.status} from year ${this.year}`;
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    this.isExportDisabled = true;
    const promiseChart = fetchStatisticsCustomerChart(this.id);
    const promiseInfo = fetchBOMCustomerPartObsolescence(this.id);
    await Promise.all([promiseChart, promiseInfo]).then((result) => {
      [this.chartData, this.data] = result;
    });
    this.isExportDisabled = false;
    this.isLoadingChartData = false;
    this.isLoadingNewRecords = false;
    this.emitListener();
  }

  get processedData(): FetchedData {
    const lengthRoot = this.dataFilteredByYearAndStatus.length;
    return {
      itemsLength: lengthRoot,
      items: this.dataFilteredByYearAndStatus,
      views: this.data.views,
    };
  }

  get dataFilteredByYearAndStatus(): FetchedItem[] {
    if (!this.year || !this.status) {
      return this.filteredData;
    }
    return this.filteredData
      .filter((e) => e[this.field] === this.status);
  }

  public async clickTableValue(payload: StatisticParameter): Promise<void> {
    this.year = payload.year;
    this.status = payload.status;
  }

  public async exportTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    const fileName = `${this.partNumber}_${this.exportTitle}_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`;
    const { items } = this.processedData;
    exportData(
      exportDataParams.format,
      items,
      fileName,
      this.exportTitle,
      exportDataParams.headers
    );
    this.isExportLoading = false;
  }
}
