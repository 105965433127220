





































































import { Component, Vue, PropSync, Emit, Prop, Watch } from 'vue-property-decorator';
import Table from '@/components/Table/Table.vue';
import FilterComponent from '@/components/Table/FilterComponent.vue';
import SearchComponent from '@/components/Dashboard/SearchComponent.vue';
import {
  Header,
  Filter,
  View,
  FetchedData,
  FetchedItem,
  Sorting,
  AdditionalDataInfo,
  IExport,
  IFilterEmit,
} from '@/interfaces';
import eventBus from '@/eventBus';

@Component({
  components: {
    FilterComponent,
    SearchComponent,
    Table,
  },
})
export default class TableComponent extends Vue {
  @PropSync('data', { required: true }) dataToDisplay!: FetchedData;

  @PropSync('loading', { required: true }) loadingData!: boolean;

  @PropSync('filters', { required: true }) filtersFromUrl!: Filter[];

  @PropSync('exportLoading', { default: false }) isExportLoading!: boolean;

  @PropSync('disabledExport', { required: true }) isExportDisabled!: boolean;

  @Prop({ type: Object }) defaultView!: View;

  @Prop({ type: Array, default: () => [] }) notSortable!: string[];

  @Prop({ type: Boolean, default: false }) grouped!: boolean;

  @Prop({ type: Boolean, default: false }) expandable!: boolean;

  @Prop({ type: Boolean, default: false }) disableFiltering!: boolean;

  @Prop({ type: Boolean, default: true }) showFooter!: boolean;

  @Prop({ type: Array, default: () => [] }) disableFilterFields!: string[];

  @Prop({ default: false }) enableXML!: boolean;

  @Prop({ default: false }) enableAll!: boolean;

  @Prop({ default: false }) expandAll!: boolean;

  @Prop({ required: false }) header!: string;

  @Watch('loading')
  onLoadingChange(): void {
    if (!this.parameters.length) {
      this.views = this.dataToDisplay.views;
      const selectedView = this.views.find((e) => e.selected);
      this.parameters = selectedView ? selectedView.fields : this.defaultView.fields;
    }
    this.loadingViews = false;
  }

  public fullscreen = false;

  public loadingViews = true;

  public itemsRange: number[] = [];

  public mainFields: Filter[] = [];

  public views: View[] = [];

  public fields: string[] = [];

  public record = [];

  get fetchedData(): FetchedItem[] {
    return this.dataToDisplay.items;
  }

  get fetchedDataLength(): number {
    return this.dataToDisplay.itemsLength;
  }

  get parameters(): string[] {
    return this.fields;
  }

  set parameters(data: string[]) {
    this.fields = data;
  }

  get tableHeaders(): Header[] {
    const headers: Header[] = [];
    if (!this.loadingViews) {
      this.dataToDisplay.items.forEach((element) => {
        Object.entries(element).forEach(([key]) => {
          if (!headers.some((item) => item.text === key.replaceAll('_', ' '))) {
            headers.push({
              text: key.replaceAll('_', ' '),
              value: key,
              sortable: !(this.notSortable.includes(key) || this.notSortable.includes('all')),
            });
          }
        });
      });
    }
    return headers;
  }

  mounted(): void {
    eventBus.$on('resetForm', async () => {
      this.views.forEach((e) => {
        if (e.default) {
          e.selected = true;
        }
      });
      const selectedView = this.views.find((e) => e.selected);
      this.parameters = selectedView ? selectedView.fields : this.defaultView.fields;
    });
  }

  public changeFilters(data: string[]): void {
    this.parameters = data;
  }

  public updateViews(views: View[]): void {
    this.views = views;
  }

  public toogleFullscreen(fullscreen: boolean): void {
    this.fullscreen = fullscreen;
  }

  @Emit('changeItemsRange')
  public changeItemsRange(range: number[]): number[] {
    this.itemsRange = range;
    return this.itemsRange;
  }

  @Emit('filterRecords')
  public filterBySubFilters(data: IFilterEmit): IFilterEmit {
    return data;
  }

  @Emit('changeSorting')
  public changeSorting(sorting: Sorting): Sorting {
    return sorting;
  }

  @Emit('expandItemInformation')
  public expandItemInformation(additionalDataInfo: AdditionalDataInfo): AdditionalDataInfo {
    return additionalDataInfo;
  }

  @Emit('exportTable')
  public exportTable(exportDataParams: IExport): IExport {
    return exportDataParams;
  }

  @Emit('fetchMoreData')
  public fetchMoreData(id: number): number {
    return id;
  }
}
