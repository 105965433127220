/* eslint-disable no-shadow */
enum UIPermissions {
  ALLOWED = 'Allowed',
  ESTIMATED_END_OF_LIFE = 'Estimated End of Life Analysis',
  CUSTOMER_PROJECT = 'Customer Project',
  VENDOR_PART_STATUS = 'Vendor Part Status Analysis',
  CHART_STATISTIC = 'Charts - Statistics',
  COMPLIANCE = 'Compliance',
  DECLARATION_DOCUMETNS = 'Declarations Documents',
  BOM_STATISTIC_OBSOLESCENCE = 'All components from BOM according to Obsolescence',
  BOM_STATISTIC_ROHS = 'All components from BOM according to RoHS',
  BOM_STATISTIC_CUSTOMER_RISK_STATUS = 'All components from BOM according to Customer Risk Status',
  BOM_ALL_COMPONENTS = 'All database - All components in BOM',
  BOM_RISK_ANALYSIS = 'Risk analysis - source 1',
  SPECIFICATION = 'Specifications',
  DECLARATION = 'Declarations',
  GENERAL_INFORMATION = 'General Information',
  MANUFACTERING_PROCESS_DATA = 'Manufacturing Process Data',
  EDA_AND_CAD_INFORMATION = 'EDA and CAD Information',
  ENVIOMENTAL_INFORMATION = 'Enviromental Information',
  TECHNICAL_INFORMATION = 'Technical Information',
  SUPPLIER_ANALYSIS = 'Supplier Analysis',
  VENDOR_TAB = 'Vendor Tab',
  COMPLIANCE_TAB = 'Can Access Compliance Tab',
  SUPPLIER_DATA = 'Supplier Data',
  PART_LIST = 'Part List/BOM',
  WHERE_USED = 'Where Used',
  PSPICE_MODEL = 'PSpice Model',
  SCHEMATIC_PART = 'Schematic Part',
  PCB_FOOTPRINT = 'PCB Footprint',
  THREE_D_MODEL = '3D Model',
  HISTORY = 'History',
  BOM_STATISTIC = 'Charts - Statistics',
  VENDOR_INFORMATION = 'Vendor Part Information',
  VENDOR_DOCUMENTS = 'Vendor Documents',
  FILTER_CUSTOMER_PROJECT_DASHBOARD = 'Can Access Customer Project Name Tab in WEB interface Page',
  ALTIUM_SCHEMATIC_PART = 'Altium Schematic Part',
  ALTIUM_PCB_FOOTPRINT = 'Altium PCB Footprint',
  CAS_NUMBER_TAB = 'Can Access CAS Number Tab in WEB interface Page',
  SUBSTANCE_NAME_TAB = 'Can Access Substance Name Tab in WEB interface Page',
  PPM_RESULT_TAB = 'Can Access PPM Result Tab in WEB interface Page',
  ALL_DATA_EXPORT = 'All data export',
  ADMINISTRATION_SETTING = 'Administration setting',
  COMPLIANCE_TOTAL = 'Compliance Total',
}

export default UIPermissions;
